import { template as template_59903975ecb74db3b3cb566f9b3d9ebd } from "@ember/template-compiler";
const FKText = template_59903975ecb74db3b3cb566f9b3d9ebd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
