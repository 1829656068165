import { template as template_6adce6b2a9fc49e28304de7af136bea7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6adce6b2a9fc49e28304de7af136bea7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
