import { template as template_91ceaac84a31488bb39eb5490104faef } from "@ember/template-compiler";
const FKControlMenuContainer = template_91ceaac84a31488bb39eb5490104faef(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
