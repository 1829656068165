import { template as template_aa7b5b14efde419088cef545724f964c } from "@ember/template-compiler";
const WelcomeHeader = template_aa7b5b14efde419088cef545724f964c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
