import { template as template_85147d9f8a3941b9b2295d941ead9dee } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_85147d9f8a3941b9b2295d941ead9dee(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
